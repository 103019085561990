/** 取得當前時間時間戳
 * params: ＋分鐘數
 */
export function getUtcTimeStamp(mins: number = 0) {
  const secs = mins * 1000 * 60
  const utcTimestamp = Math.floor((new Date().getTime() + secs) / 1000.0)
  return utcTimestamp
}

/** 超過一千變成k ***/
export function formatNumber(number: number) {
  if (number < 1000) {
    return String(number)
  } else {
    const quotient = Math.floor(number / 100) / 10
    return `${quotient}K`
  }
}

/** 千分位分號 ***/
export function addCommas(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
/** 移除網址參數（不重新整理頁面）
 *  paramsToDelete 帶入指定要移除的字串陣列，如沒有傳則預設為清空所有參數
 */
export function deleteUrlParams(paramsToDelete?: string[]): void {
  const newParams = new URLSearchParams(window.location.search)
  let newURLParams
  if (paramsToDelete && paramsToDelete.length > 0) {
    paramsToDelete.forEach(string => {
      newParams.delete(string)
    })
    newURLParams = newParams.toString()
  } else {
    newURLParams = ''
  }
  const currentURL = window.location.href
  const newURL =
    currentURL.split('?')[0] + (newURLParams ? '?' + newParams.toString() : '')
  // 使用 History API 更改 URL，而不重新整理頁面
  window.history.pushState({ path: newURL }, '', newURL)
}

/** 動態增加網址參數（不重新整理頁面）
 *  paramsToAdd 帶入指定要移除的{key: value} 物件
 */
export function addUrlParams(paramsToAdd: object): void {
  const newParams = new URLSearchParams(window.location.search)
  Object.entries(paramsToAdd).forEach(([key, value]) => {
    newParams.set(key, value)
  })
  const currentURL = window.location.href
  const newURL = currentURL.split('?')[0] + '?' + newParams.toString()

  // 使用 History API 更改 URL，而不重新整理頁面
  window.history.pushState({ path: newURL }, '', newURL)
}
