import { get, post, put, del, uploader } from './axios'
import CryptoJS from 'crypto-js'
// import store from '@/store/index.js'

const decryptWithAES = (ciphertext: string) => {
  const key = CryptoJS.enc.Utf8.parse(import.meta.env.VITE_SEO_KEY)
  const iv = CryptoJS.enc.Utf8.parse(import.meta.env.VITE_SEO_AESIV)

  const decrypted = CryptoJS.AES.decrypt(ciphertext, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })

  try {
    return CryptoJS.enc.Utf8.stringify(decrypted)
  } catch (e) {
    console.error('Error: Malformed UTF-8 data')
    return null
  }
}

const seoApiKey = decryptWithAES(import.meta.env.VITE_SEO_APIKEY)

interface Api {
  PostLoginThirdParty: (params: any) => Promise<any>
  PostAppleLogin: (params: any) => Promise<any>
  PostTokenRefresh: (params: any) => Promise<any>
  GetConfig: () => Promise<any>
  GetMemberPoiCategory: () => Promise<any>
  GetAllPoiCategory: (params: any) => Promise<any>
  UpdateMemberPoiCategory: (params: any) => Promise<any>
  GetAllLocation: () => Promise<any>
  GetPopularDestination: () => Promise<any>
  GetRecommend: () => Promise<any>
  GetLocationByMap: (params: any) => Promise<any>
  GetLocationByKeyword: (params: any) => Promise<any>
  GetPoiById: (params: any) => Promise<any>
  PostAddCustomPoi: (params: any) => Promise<any>
  PostFavorite: (params: any) => Promise<any>
  PostMediaLike: (params: any) => Promise<any>
  GetTravelScheduleList: (params: any) => Promise<any>
  GetTravelScheduleDetail: (params: any) => Promise<any>
  GetTSDDetail: (params: any) => Promise<any>
  GetTravelScheduleWithDetail: (params: any) => Promise<any>
  GetSystemCoverList: () => Promise<any>
  PostAddTravelSchedule: (params: any) => Promise<any>
  DeleteTravelSchedule: (params: any) => Promise<any>
  PostCopyTravelSchedule: (params: any) => Promise<any>
  GetCollaborationList: (params: any) => Promise<any>
  GetCollaborationLink: (params: any) => Promise<any>
  GetCollaborationWithDetail: (params: any) => Promise<any>
  GetAddWhereBestAll: (params: any) => Promise<any>
  GetAddWhere: (params: any) => Promise<any>
  PutSetCover: (params: any) => Promise<any>
  GetRouteList: (params: any) => Promise<any>
  GetFormulaTrafficTime: (params: any) => Promise<any>
  GetTravelScheduleShareLink: (params: any) => Promise<any>
  PostCreatePreViewDeepLink: (params: any) => Promise<any>
  GetTravelScheduleShareText: (params: any) => Promise<any>
  PostTravelScheduleShareSendEmail: (params: any) => Promise<any>
  PostCreatePoiInfoDeepLink: (params: any) => Promise<any>
  PostCreateCollaborationDeepLink: (params: any) => Promise<any>
  PostCreateProfileDeepLink: (params: any) => Promise<any>
  PostAddTravelScheduleCollaboration: (params: any) => Promise<any>
  GetTravelScheduleCollaboration: (params: any) => Promise<any>
  PutUpdatePermissionByOwner: (params: any) => Promise<any>
  DeleteQuitTravelScheduleCollaboration: (params: any) => Promise<any>
  DeleteByOwner: (params: any) => Promise<any>
  GetMemberInfo: (params: any) => Promise<any>
  GetPhotoListPaging: (params: any) => Promise<any>
  GetFilmListPaging: (params: any) => Promise<any>
  FollowMember: (params: any) => Promise<any>
  UnFollowMember: (params: any) => Promise<any>
  BindHotai: (params: any) => Promise<any>
  UpdateMedia: (params: any) => Promise<any>
  DeleteMedia: (params: any) => Promise<any>
  GetUpdateMemberInfo: (params: any) => Promise<any>
  UpdateMember: (params: any) => Promise<any>
  UpdateMemberPhotoBase64: (params: any) => Promise<any>
  DeleteMember: (params: any) => Promise<any>
  GetTravelScheduleUserLabel: () => Promise<any>
  PostTravelScheduleUserLabelAdd: (params: any) => Promise<any>
  PutUpdateTravelScheduleUserLabel: (params: any) => Promise<any>
  PutSortTravelScheduleUserLabel: (params: any) => Promise<any>
  DeleteTravelScheduleUserLabel: (params: any) => Promise<any>
  PutTravelScheduleUpdate: (params: any) => Promise<any>
  PostAddTsd: (params: any) => Promise<any>
  PostCopyTsd: (params: any) => Promise<any>
  PutUpdateTravelScheduleCoverBase64: (params: any) => Promise<any>
  PostUploadTravelScheduleCoverBase64: (params: any) => Promise<any>
  DeleteTsd: (params: any) => Promise<any>
  GetTSDNote: (params: any) => Promise<any>
  PutTSDNote: (params: any) => Promise<any>
  GetEditInfo: (params: any) => Promise<any>
  PutUpdateInfo: (params: any) => Promise<any>
  PutTsdSort: (params: any) => Promise<any>
  GetRouteDetail: (params: any) => Promise<any>
  PutSetRoute: (params: any) => Promise<any>
  PutSetCustomRoute: (params: any) => Promise<any>
  GetVerifyUpdateTime: (params: any) => Promise<any>
  GetTravelScheduleNote: (params: any) => Promise<any>
  PutUpdateTravelScheduleNote: (params: any) => Promise<any>
  PostCreateMainDeepLink: (params: any) => Promise<any>
  DeleteDeleteDay: (params: any) => Promise<any>
  PutUpdateStartDate: (params: any) => Promise<any>
  GetLikeMediaListPaging: (params: any) => Promise<any>
  GetPreview: (params: any) => Promise<any>
  PostPlaylistTravelImport: (params: any) => Promise<any>
  GetSeoHome: (params: any) => Promise<any>
  GetSeoPoiDetail: (params: any) => Promise<any>
  GetSeoMemberCenter: (params: any) => Promise<any>
  GetMemberSettings: () => Promise<any>
  PutUpdateTravelScheduleSort: (params: any) => Promise<any>
  GetModifyRedirectUri: () => Promise<any>
  PutUpdateMemberNickname: (params: any) => Promise<any>
  PutUpdateMemberIntroduction: (params: any) => Promise<any>
  PutUpdateMemberPersonalInfo: (params: any) => Promise<any>
  PutUpdateMemberSocialMedia: (params: any) => Promise<any>
  PostConfirmCenterUnbindMember: () => Promise<any>
  GetActiveAdvertiseByPosition: (params: any) => Promise<any>
}

const api: Api = {
  /** 01.登入 - 第三方登入 */
  PostLoginThirdParty: params => {
    return post(`/Login/ThirdParty`, params, false)
  },
  /** 01.登入 - 第三方登入 - Apple */
  PostAppleLogin: params => {
    return post(`/Login/AppleLogin`, params, false)
  },
  /** 01.登入 - 更新Token */
  PostTokenRefresh: params => {
    return post(`/Token/Refresh`, params)
  },
  /** 01.登入 - 取得Config */
  GetConfig: () => {
    return get(`/Config/GetConfig`)
  },
  /** 02. 地圖 - 會員的POI類別 */
  GetMemberPoiCategory: () => {
    return get(`/PoiClassification/GetMember`)
  },
  /** 02. 地圖 - 全部的POI類別 */
  GetAllPoiCategory: params => {
    return get(`/PoiClassification/GetAll`, params)
  },
  /** 02. 地圖 - 更新會員的POI類別 */
  UpdateMemberPoiCategory: params => {
    return put(`/PoiClassification/UpdateMember`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },
  /** 02. 地圖 - 取得全部區域資料 */
  GetAllLocation: () => {
    return get(`/Location/GetAllLocation`)
  },
  /** 02. 地圖 - 取得熱門目的地 */
  GetPopularDestination: () => {
    return get(`/Location/GetPopularDestination`)
  },
  /** 02. 地圖 - 取得地區推薦 */
  GetRecommend: () => {
    return get(`/Location/GetRecommend`)
  },
  /** 02. 地圖 - 地圖模式 */
  GetLocationByMap: params => {
    return get(`/Poi/Search/Get`, params)
  },
  /** 02. 地圖 - 關鍵字搜尋Poi */
  GetLocationByKeyword: params => {
    return get(`/PoiSearch/SearchByKeyword`, params)
  },
  /** 02. 地圖 - 景點明細 */
  GetPoiById: params => {
    return get(`/Poi/GetPoiById`, params)
  },
  /** 02. 地圖 - 貢獻影音(圖片/影片) */
  PostAddCustomPoi: params => {
    return post(`/Poi/AddCustomPoiForWeb`, params)
  },
  /** 02. 地圖 - 景點最愛 */
  PostFavorite: params => {
    return post(`/Poi/Favorite/Post`, null, true, { params })
  },
  /** 02. 地圖 - 素材點讚 */
  PostMediaLike: params => {
    return post(`/Poi/MediaLike/Post`, null, true, { params })
  },

  /** 03. 行程標籤 - 取得會員的行程標籤列表 */
  GetTravelScheduleUserLabel: () => {
    return get(`/TravelScheduleUserLabel/Get`)
  },

  /** 03. 行程標籤 - 新增會員的行程標籤 */
  PostTravelScheduleUserLabelAdd: params => {
    return post(`/TravelScheduleUserLabel/Add`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 03. 行程標籤 - 修改會員的行程標籤 */
  PutUpdateTravelScheduleUserLabel: params => {
    return put(`/TravelScheduleUserLabel/Update`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 03. 行程標籤 - 排序會員的行程標籤 */
  PutSortTravelScheduleUserLabel: params => {
    return put(`/TravelScheduleUserLabel/Sort`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 03. 行程標籤 - 刪除會員的行程標籤 */
  DeleteTravelScheduleUserLabel: params => {
    return del(`/TravelScheduleUserLabel/Delete`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 04.行程列表 - 取得行程列表 */
  GetTravelScheduleList: params => {
    return get(`/TravelSchedule/Get`, params)
  },

  /** 04.行程列表 - 取得行程列表包含細節 */
  GetTravelScheduleWithDetail: params => {
    return get(`/TravelSchedule/GetWithDetail`, params)
  },

  /** 04.行程列表 - 取得系統行程封面圖列表 */
  GetSystemCoverList: () => {
    return get(`/TravelSchedule/GetSystemCoverList`)
  },

  /** 04.行程列表 - 新增行程 */
  PostAddTravelSchedule: params => {
    return post(`/TravelSchedule/Add`, params, true, {
      headers: {
        language: 'zh-tw',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 04.行程列表 - 刪除行程 */
  DeleteTravelSchedule: params => {
    return del(`TravelSchedule/Delete`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 04.行程列表 - 複製行程 */
  PostCopyTravelSchedule: params => {
    return post(`/TravelSchedule/Copy`, params, true, {
      headers: {
        language: 'zh-tw',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 04.行程列表 - 修改行程 */
  PutTravelScheduleUpdate: params => {
    return put(`/TravelSchedule/Update`, params, true, {
      headers: {
        language: 'zh-tw',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 04.行程列表 - 會員修改行程封面圖 Base64 */
  PutUpdateTravelScheduleCoverBase64: params => {
    return put(`/Media/UpdateTravelScheduleCoverBase64`, { params })
  },

  /** 04.行程列表 - 會員新增行程封面圖 Base64 */
  PostUploadTravelScheduleCoverBase64: params => {
    return post(`/Media/UploadTravelScheduleCoverBase64`, params)
  },

  /** 04.行程列表 - 取得行程筆記 */
  GetTravelScheduleNote: params => {
    return get(`/TravelSchedule/GetNote`, params)
  },

  /** 04.行程列表 - 修改行程筆記 */
  PutUpdateTravelScheduleNote: params => {
    return put(`/TravelSchedule/UpdateNote`, params)
  },

  /** 04.行程列表 - 行程刪除某一天 */
  DeleteDeleteDay: params => {
    return del(`/TravelSchedule/DeleteDay`, params)
  },

  /** 05.DeepLink + Log - 預覽行程 DeepLink */
  PostCreatePreViewDeepLink: params => {
    return post(`/DeepLink/CreatePreViewDeepLink`, params)
  },

  /** 05.DeepLink + Log - 產生 景點明細頁 DeepLink */
  PostCreatePoiInfoDeepLink: params => {
    return post(`/DeepLink/CreatePoiInfoDeepLink`, params)
  },
  /** 05.DeepLink + Log - 產生 邀請共編頁 DeepLink */
  PostCreateCollaborationDeepLink: params => {
    return post(`/DeepLink/CreateCollaborationDeepLink`, params)
  },
  /** 05.DeepLink + Log - 產生 個人資訊頁面 DeepLink */
  PostCreateProfileDeepLink: params => {
    return post(`/DeepLink/CreateProfileDeepLink`, params)
  },

  /** 06.行程共編和分享 - 取得行程分享連結 */
  GetTravelScheduleShareLink: params => {
    return get(`/TravelScheduleShare/GetLink`, params)
  },

  /** 06. 行程共編和分享 - 取得與我共編的行程 */
  GetCollaborationList: params => {
    return get(`/TravelSchedule/GetCollaboration`, params)
  },
  /** 06. 分享 - 取得行程共編分享連結 */
  GetCollaborationLink: params => {
    return get(`/Share/GetCollaborationLink`, params)
  },

  /** 06. 行程共編和分享 - 取得與我共編的行程包含細節 */
  GetCollaborationWithDetail: params => {
    return get(`/TravelSchedule/GetCollaborationWithDetail`, params)
  },

  /** 06. 行程共編和分享 - 取得行程分享文字檔 */
  GetTravelScheduleShareText: params => {
    return get(`/TravelScheduleShare/GetText`, params)
  },

  /** 06. 行程共編和分享 - 發送分享行程Email */
  PostTravelScheduleShareSendEmail: params => {
    return post(`/TravelScheduleShare/SendEmail`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 06. 行程共編和分享 - 新增行程共同編輯會員 */
  PostAddTravelScheduleCollaboration: params => {
    return post(`/TravelScheduleCollaboration/Add`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 06. 行程共編和分享 - 取得行程共同編輯列表 */
  GetTravelScheduleCollaboration: params => {
    return get(`/TravelScheduleCollaboration/Get`, params)
  },

  /** 06. 行程共編和分享 - 擁有者修改行程共同編輯權限 */
  PutUpdatePermissionByOwner: params => {
    return put(
      `/TravelScheduleCollaboration/UpdatePermissionByOwner`,
      params,
      true,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
  },

  /** 06. 行程共編和分享 - 自己退出行程共同編輯 */
  DeleteQuitTravelScheduleCollaboration: params => {
    return del(`/TravelScheduleCollaboration/Quit`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 06. 行程共編和分享 - 擁有者刪除行程共同編輯會員 */
  DeleteByOwner: params => {
    return del(`/TravelScheduleCollaboration/DeleteByOwner`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 07. 會員中心 - 取得會員中心資訊 */
  GetMemberInfo: params => {
    return get(`/MemberCenter/GetMemberInfo`, params)
  },
  /** 07. 會員中心 - 取得會員照片列表 分頁 */
  GetPhotoListPaging: params => {
    return get(`/MemberCenter/GetPhotoListPaging`, params)
  },
  /** 07. 會員中心 - 取得會員影片列表 分頁 */
  GetFilmListPaging: params => {
    return get(`/MemberCenter/GetFilmListPaging`, params)
  },
  /** 07. 會員中心 - 追蹤會員 */
  FollowMember: params => {
    return post(`/MemberCenter/FollowMember`, params)
  },
  /** 07. 會員中心 - 取消追蹤會員 */
  UnFollowMember: params => {
    return del(`/MemberCenter/UnFollowMember`, params)
  },
  /** 07. 會員中心 - 綁定和泰會員 */
  BindHotai: params => {
    return post(`/MemberCenter/BindHotai`, params)
  },
  /** 07. 會員中心 - 更新素材權限和連結 */
  UpdateMedia: params => {
    return put(`/MemberCenter/UpdateMedia`, params)
  },
  /** 07. 會員中心 - 刪除素材 */
  DeleteMedia: params => {
    return del(`/MemberCenter/DeleteMedia`, params)
  },
  /** 07. 會員中心 - 取得更新會員資料 */
  GetUpdateMemberInfo: params => {
    return get(`/MemberCenter/GetUpdateMemberInfo`, params)
  },
  /** 07. 會員中心 - 修改會員資料 */
  UpdateMember: params => {
    return put(`/MemberCenter/UpdateMember`, params)
  },
  /** 07. 會員中心 - 修改會員中心照片 Base64 */
  UpdateMemberPhotoBase64: params => {
    return put(`/Media/UpdateMemberPhotoBase64`, params)
  },
  /** 07. 會員中心 - 刪除會員 */
  DeleteMember: params => {
    return del(`/MemberCenter/DeleteMember`, params)
  },

  /** 08. 行程細節 - 行程TSD列表 */
  GetTravelScheduleDetail: params => {
    return get(`/TravelScheduleDetail/Get`, params)
  },

  /** 08. 行程細節 - TSD明細 */
  GetTSDDetail: params => {
    return get(`/TravelScheduleDetail/GetDetail`, params)
  },

  /** 08. 行程細節 - 加入TSD最推薦是第幾天 */
  GetAddWhereBestAll: params => {
    return get(`/TravelScheduleDetail/GetAddWhereBestAll`, params)
  },

  /** 08. 行程細節 - 查詢TSD要放在哪裡 */
  GetAddWhere: params => {
    return get(`/TravelScheduleDetail/GetAddWhere`, params)
  },

  /** 08. 行程細節 - 設定TSD封面 */
  PutSetCover: params => {
    return put(`/TravelScheduleDetail/SetCover`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 08. 行程細節 - 新增TSD */
  PostAddTsd: params => {
    return post(`/TravelScheduleDetail/Add`, params)
  },

  /** 08. 行程細節 - 複製TSD */
  PostCopyTsd: params => {
    return post(`/TravelScheduleDetail/Copy`, params)
  },

  /** 08. 行程細節 - 刪除TSD */
  DeleteTsd: params => {
    return del(`/TravelScheduleDetail/Delete`, params)
  },

  /** 08. 行程細節 - 取得 TSD 筆記 */
  GetTSDNote: params => {
    return get(`/TravelScheduleDetail/GetNote`, params)
  },

  /** 08. 行程細節 - 更新 TSD 筆記 */
  PutTSDNote: params => {
    return put(`/TravelScheduleDetail/UpdateNote`, params)
  },

  /** 08. 行程細節 - 取得編輯TSD資訊 */
  GetEditInfo: params => {
    return get(`/TravelScheduleDetail/GetEditInfo`, params)
  },

  /** 08. 行程細節 - 編輯TSD */
  PutUpdateInfo: params => {
    return put(`/TravelScheduleDetail/Update`, params)
  },

  /** 08. 行程細節 - 排序TSD */
  PutTsdSort: params => {
    return put(`/TravelScheduleDetail/Sort`, params)
  },

  /** 08. 行程細節 - 檢查行程更新時間 */
  GetVerifyUpdateTime: params => {
    return get(`/TravelScheduleDetail/VerifyUpdateTime`, params)
  },

  /** 08. 行程細節 - 修改行程開始時間 */
  PutUpdateStartDate: params => {
    return put(`TravelSchedule/UpdateStartDate`, params)
  },

  /** 08. 行程細節 - 預覽行程 */
  GetPreview: params => {
    return get(`TravelScheduleDetail/Preview`, params)
  },

  /** 09. 交通路線 - 查詢簡易交通時間 */
  GetFormulaTrafficTime: params => {
    return get(`/TravelScheduleDetailRoute/GetFormulaTrafficTime`, params)
  },

  /** 09. 交通路線 - 交通路徑-列表 */
  GetRouteList: params => {
    return get(`/TravelScheduleDetailRoute/GetRouteList`, params)
  },

  /** 09. 交通路線 - 交通路徑-細節資訊 */
  GetRouteDetail: params => {
    return get(`/TravelScheduleDetailRoute/GetRouteDetail`, params)
  },

  /** 09. 交通路線 - 交通路徑-TSD的設定路徑 */
  PutSetRoute: params => {
    return put(`/TravelScheduleDetail/SetRoute`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  /** 09. 交通路線 - 交通路徑-TSD的設定自訂路徑 */
  PutSetCustomRoute: params => {
    return put(`/TravelScheduleDetail/SetCustomRoute`, params, true, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },
  /**  產生 首頁(景點牆) DeepLink (純打開App/無安裝則導至app下載) */
  PostCreateMainDeepLink: params => {
    return post(`/DeepLink/CreateMainDeepLink`, params)
  },
  /**  取得會員按讚素材列表 */
  GetLikeMediaListPaging: params => {
    return get(`/MemberCenter/GetLikeMediaListPaging`, params)
  },
  /**  playlist 匯入 travelSchedule */
  PostPlaylistTravelImport: params => {
    return post(`/Playlist/TravelImport`, params)
  },
  /**  取得SEO資料 - 首頁 */
  GetSeoHome: params => {
    return get(`/Seo/Home`, params, false, {
      headers: {
        ApiKey: seoApiKey,
      },
    })
  },
  /**  取得SEO資料 - POI詳情 */
  GetSeoPoiDetail: params => {
    return get(`/Seo/PoiDetail`, params, false, {
      headers: {
        ApiKey: seoApiKey,
      },
    })
  },
  /**  取得SEO資料 - 會員 */
  GetSeoMemberCenter: params => {
    return get(`/Seo/MemberCenter`, params, false, {
      headers: {
        ApiKey: seoApiKey,
      },
    })
  },
  /** 取得會員設定資料 */
  GetMemberSettings: () => {
    return get(`/MemberCenter/GetMemberSettings`)
  },
  /** 更新會員預設行程排序 */
  PutUpdateTravelScheduleSort: params => {
    return put(`/MemberCenter/UpdateTravelScheduleSort`, params)
  },

  // 取得修改和泰會員資料URL
  GetModifyRedirectUri: () => {
    return get(`/HotaiMember/GetModifyRedirectUri`)
  },

  // 更新會員 - 暱稱
  PutUpdateMemberNickname: params => {
    return put(`/MemberCenter/UpdateMemberNickname`, params)
  },

  // 更新會員 - 個人簡介
  PutUpdateMemberIntroduction: params => {
    return put(`/MemberCenter/UpdateMemberIntroduction`, params)
  },

  // 更新會員 - 個人資料(Email 性別 生日)
  PutUpdateMemberPersonalInfo: params => {
    return put(`/MemberCenter/UpdateMemberPersonalInfo`, params)
  },

  // 更新會員 - 社群連結
  PutUpdateMemberSocialMedia: params => {
    return put(`/MemberCenter/UpdateMemberSocialMedia`, params)
  },

  // 確認和泰會員中心通知解除綁定已收到
  PostConfirmCenterUnbindMember: () => {
    return post(`/HotaiMember/ConfirmCenterUnbindMember`)
  },

  // 取得版位有效廣告(單則)
  GetActiveAdvertiseByPosition: params => {
    return post(`/Advertise/GetActiveAdvertiseByPosition`, params, true, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
}

export default api
